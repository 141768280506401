import { FLP_USE_ETHEREUM_MAINNET } from '../../helpers/constants/envVars';

const lendingPoolV2AddressGoerli = '0x4bd5643ac6f66a5237E18bfA7d47cF22f1c9F210';

const lendingPollV2AddressMainnet =
  '0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9';

export const lendingPoolV2Address = FLP_USE_ETHEREUM_MAINNET
  ? lendingPollV2AddressMainnet
  : lendingPoolV2AddressGoerli;

const priceOracleAddressGoerli = '0xc1c6f3b788FE7F4bB896a2Fad65F5a8c0Ad509C9';

const priceOracleAddressMainnet = '0xA50ba011c48153De246E5192C8f9258A2ba79Ca9';

export const priceOracleAddress = FLP_USE_ETHEREUM_MAINNET
  ? priceOracleAddressMainnet
  : priceOracleAddressGoerli;
