import aaveAddresses from 'ethereum/constants/aaveAddresses';

/** use backend api instead */
/** @deprecated */
const getVariableDebTokenAddress = (
  tokenContractAddress: string
): string | undefined =>
  aaveAddresses.proto.find(
    (tokenData) =>
      tokenData.address.toLowerCase() === tokenContractAddress.toLowerCase()
  )?.variableDebtTokenAddress;

export default getVariableDebTokenAddress;
