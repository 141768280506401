import { BigNumber, Contract, ethers } from 'ethers';
import { priceOracleAddress } from 'ethereum/constants/contracts';
import priceOracleAbi from 'ethereum/abis/priceOracle';
import { Asset } from 'api/requestWrappers/AssetsRequest';
import { valueInAssetUnits_to_BigNumber } from 'helpers/functions/numbers';
import { defaultToastOptions, ToastAlert } from 'alerts/ToastAlert';
import {
  BigNumberErrorCode,
  BigNumberErrorFault,
} from 'helpers/constants/bigNumber';
import { useTranslation } from 'react-i18next';

const { Web3Provider } = ethers.providers;
const { ethereum } = window;

const useGetAssetValueInEthValue = () => {
  const { t } = useTranslation();
  const getAssetValueInEthValue = async (
    asset: Asset,
    rawAmount: string | undefined = undefined
  ): Promise<BigNumber | null> => {
    if (!ethereum?.isConnected()) {
      console.error(
        'Cannot get asset value in eth value! Check if ethereum object exists or supported assets are fetched!'
      );
      return null;
    }
    const provider = new Web3Provider(ethereum);
    const priceOracleContract = new Contract(
      priceOracleAddress,
      priceOracleAbi,
      provider
    );

    try {
      const ethPricePerUnit: BigNumber =
        await priceOracleContract.getAssetPrice(asset.address);
      if (rawAmount) {
        return ethPricePerUnit
          .mul(valueInAssetUnits_to_BigNumber(rawAmount, asset.decimals))
          .div(BigNumber.from(10).pow(asset.decimals));
      }
      return ethPricePerUnit;
    } catch (e: any) {
      console.error(e);
      if (e?.fault === BigNumberErrorFault.UNDERFLOW) {
        ToastAlert.create({
          ...defaultToastOptions,
          toastId: BigNumberErrorCode.NUMERIC_FAULT,
        }).showError(t('general.underflowError', { count: asset.decimals }));
      }
      return null;
    }
  };

  return { getAssetValueInEthValue };
};

export default useGetAssetValueInEthValue;
